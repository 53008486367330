import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import GatsbyImage from "gatsby-image"

const Header = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "cyberdelphos-white.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `);
  let [showHamMenu, setShowHamMenu] = useState(false);

  const toggleHam = () => {
    setShowHamMenu(!showHamMenu);
  }

  return (
    <header>
      <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
        <GatsbyImage className="navbar-logo" fluid={data.file.childImageSharp.fluid} alt="Cyberdelphos mascot"/>
        <Link className="navbar-brand" to="/">Cyberdelphos</Link>
        <button className="navbar-toggler" type="button"  data-target="#navbarCollapse"
                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleHam} >
          <span className="navbar-toggler-icon"/>
        </button>
        <div className={showHamMenu ? 'navbar-collapse' : 'collapse navbar-collapse'} id="navbarCollapse">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">Inicio</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/blog">Blog</Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
